import CustomNextLink from '@/components/CustomNextLink';
import routes from '../../routes/routes';
export default function SellSection() {
  return (
    <div className="relative mx-2 sm:mx-4 lg:mx-20 my-10 sm:my-24">
      <img
        src="/images/start-selling.png"
        className="w-full h-60 object-cover sm:h-auto sm:object-fill rounded-md"
        alt="Secure Trade Club, start selling on STC"
      />
      <div className="sell-section-gradient rounded-md" />
      <div className="flex flex-col sm:flex-row justify-center sm:justify-between items-center absolute top-0 bottom-0 gap-6 xs:gap-8 sm:gap-24 lg:gap-36 px-7 lg:px-14">
        <div className="flex flex-col gap-3 md:gap-8">
          <h4 className="font-extrabold text-white text-xl xs:text-2xl lg:text-4xl xl:text-6xl">
            List your collectibles with Secure Trade Club
          </h4>
          <p className=" text-sm xs:text-base lg:text-lg xl:text-2xl text-white">
            We offer low fees, nationwide buyers and tools to help you sell and showcase your items
          </p>
        </div>
        <CustomNextLink to={routes.signUp()}>
          <button className="btn-new btn-primary">Join STC Today!</button>
        </CustomNextLink>
      </div>
    </div>
  );
}
