import { useShowcaseItemsQuery } from '@/api/showcaseItems/showcaseItems.api';
import StandaloneLoader from '@/components/@next/StandaloneLoader/StandaloneLoader';
import AuctionCardHorizontal from '@/features/auctions/components/AuctionCardHorizontal';
import routes from '@/routes/routes';
import Link from 'next/link';
import { useState } from 'react';
import AuctionFilterBar, { AuctionTypeFilter } from './AuctionFilterBar';
import NewAuctionButton from './Components/NewAuctionButton';

export default function LiveAuctions() {
  const [auctionInfo, setAuctionInfo] = useState<{
    auctionType: AuctionTypeFilter;
    isAuctionClosed: boolean;
  }>({
    isAuctionClosed: false,
    auctionType: undefined,
  });

  const limit = 10;
  const { data: items, isLoading } = useShowcaseItemsQuery({
    limit,
    orderBy: 'newest',
    isWantedItem: false,
    forAuction: true,
    ...auctionInfo,
  });

  return (
    <div className="live-auctions-bg-gradient py-20 mb-10 md:mb-36">
      <h2 className="text-center text-white text-xl md:text-2xl font-bold mb-9">
        Check Out Auctions
      </h2>

      <div className="px-2 xl:px-32 mb-9 flex items-center justify-between">
        <AuctionFilterBar
          filters={auctionInfo}
          onChangeFilters={(filters: {
            auctionType: AuctionTypeFilter;
            isAuctionClosed: boolean;
          }) => setAuctionInfo(filters)}
        />
      </div>

      {isLoading && (
        <div className="text-center">
          <StandaloneLoader />
        </div>
      )}
      {items && items.length > 0 && (
        <div className="flex flex-col items-center">
          <div className="flex flex-col md:flex-row md:flex-wrap px-2 xl:px-32 gap-x-5 gap-y-6 w-full items-center">
            {items.map((item) => (
              <AuctionCardHorizontal key={item.id} item={item} />
            ))}
          </div>
          <Link
            href={{
              pathname: routes.auctions.list,
              query: {
                ...auctionInfo,
              },
            }}
          >
            <a>
              <button className="btn-new btn-primary mt-16">View All Auctions</button>
            </a>
          </Link>
        </div>
      )}

      {items?.length === 0 && (
        <p className="text-center text-white text-xl mx-2">
          {auctionInfo.isAuctionClosed
            ? 'There are no finished auctions'
            : auctionInfo.auctionType === 'fantasy'
            ? 'Stay Tuned for Our Next Fantasy Auctions Launching Soon!'
            : auctionInfo.auctionType === 'charity'
            ? 'Stay Tuned for More Charity Auctions Launching Soon!'
            : 'Stay Tuned for Our Next Auctions Launching Soon!'}
        </p>
      )}
      <div className="flex flex-col mt-10 items-center">
        <NewAuctionButton />
      </div>
    </div>
  );
}
