import routes from '@/routes/routes';
import Link from 'next/link';
import { FiArrowLeft, FiDollarSign, FiRadio } from 'react-icons/fi';
import { AnimatedModal } from '../@next/AnimatedModal/AnimatedModal';

type Props = {
  showModal: boolean;
  onClose: () => void;
  currentUserId: number;
};
const NewLiveStreamModal = ({ showModal, onClose, currentUserId }: Props) => {
  return (
    <AnimatedModal showModal={showModal} onBackdropClick={onClose}>
      <div className="bg-secondary-darker md:h-full md:w-auto">
        <div className="flex justify-start text-white gap-4 border-b p-3">
          <FiArrowLeft size={30} onClick={onClose} />
          <span className="font-bold text-lg">Choose Live Stream type</span>
        </div>
        <div className="md:mx-20 my-10 pb-10 flex flex-col gap-y-4">
          <Link href={routes.newLiveStream.auction}>
            <a>
              <button className="btn-new btn-primary !px-2">
                Live Stream with Auction
                <FiDollarSign size={18} className="ml-2" />
              </button>
            </a>
          </Link>
          <Link href={routes.newLiveStream.normal}>
            <a>
              <button className="btn-new btn-primary-outline !px-2">
                Normal Live Stream
                <FiRadio size={18} className="ml-2" />
              </button>
            </a>
          </Link>
        </div>
      </div>
    </AnimatedModal>
  );
};
export default NewLiveStreamModal;
