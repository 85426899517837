import CustomNextLink from '@/components/CustomNextLink';
import { useEffect, useState } from 'react';
import { PropertyDto, useProperties } from '../../../api/property/properties.api';
import StandaloneLoader from '../../../components/@next/StandaloneLoader/StandaloneLoader';
import routes from '../../../routes/routes';
export default function Categories() {
  const [sports, setSports] = useState<Array<PropertyDto>>();
  const { data: propertiesData, isLoading } = useProperties({ parentId: 1, level: 1 });
  useEffect(() => {
    if (propertiesData) setSports(propertiesData);
  }, [propertiesData]);

  return (
    <div>
      <h2 className="text-center text-secondary text-xl md:text-2xl font-bold mb-10 md:mb-14">
        Browse by category
      </h2>
      {isLoading && <StandaloneLoader />}
      <div className="grid grid-cols-2 gap-2 px-2 md:gap-4 md:px-16 lg:px-32 xs:grid-cols-3 lg:grid-cols-4 ">
        {sports?.map((sport) => {
          if (!sport.isSelectable) return;
          return (
            <div className="relative" key={sport.id}>
              <CustomNextLink className="relative block" to={routes.sport(sport.slug)}>
                <img
                  src={sport.image ?? '/images/no_image.jpeg'}
                  className="rounded-md w-full h-auto"
                  alt={sport.name}
                />
                <div className="category-gradient rounded-md" />

                <span className="absolute left-3 md:left-6 bottom-3 md:bottom-6 text-white font-bold text-lg md:text-2xl">
                  {sport.name}
                </span>
              </CustomNextLink>
            </div>
          );
        })}
      </div>
    </div>
  );
}
