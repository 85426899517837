import Link from 'next/link';
import routes from '../../routes/routes';
import Categories from './categories/Categories';
import { Charities } from './Charities';
import styles from './homePage.module.scss';
import LiveAuctions from './liveAuctions/LiveAuctions';
import { LiveStreams } from './liveStreams/LiveStreams';
import SellSection from './SellSection';
import ShowcaseItems from './showcaseItems/ShowcaseItems';
export default function Home() {
  return (
    <main className={styles['home-page']}>
      <section className="header-section">
        <div className="cover" />
        <div className="content flex flex-col items-center justify-center h-full">
          <div className="flex flex-col-reverse lg:flex-row items-center justify-between">
            <h1 className="text-3xl lg:text-7xl lg:text-left text-white font-extrabold items-start text-left">
              Buy, sell & trade all collectibles
            </h1>
            <img className="" src="/images/stc_logo.png" alt="Secure Trade Club - STC" />
          </div>
          <div className="flex flex-row items-start gap-x-6 self-center lg:self-start mt-10 lg:-mt-10">
            <Link href={routes.search()}>
              <a>
                <button className="btn-new btn-white">Shop</button>
              </a>
            </Link>
            <Link href={routes.addItem}>
              <a>
                <button className="btn-new btn-primary">Sell Collectibles</button>
              </a>
            </Link>
          </div>
        </div>
      </section>

      <LiveStreams />
      <ShowcaseItems />
      <Charities />
      <LiveAuctions />
      <Categories />
      <SellSection />
    </main>
  );
}
