import { AuctionTypeEnum } from '@/api/showcaseItems/showcaseItems.api';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import { useClickAway } from 'react-use';

export type AuctionTypeFilter = AuctionTypeEnum | undefined;
const AuctionFilterBar = ({
  filters,
  onChangeFilters,
}: {
  filters: {
    auctionType: AuctionTypeFilter;
    isAuctionClosed: boolean;
  };
  onChangeFilters: (filters: { auctionType: AuctionTypeFilter; isAuctionClosed: boolean }) => void;
}) => {
  return (
    <>
      <StatusSelect
        isAuctionClosed={filters.isAuctionClosed}
        onChange={(isAuctionClosed) => onChangeFilters({ ...filters, isAuctionClosed })}
      />

      <TypeSelect
        value={filters.auctionType}
        onChange={(auctionType) => onChangeFilters({ ...filters, auctionType })}
      />
    </>
  );
};

export default AuctionFilterBar;

const StatusSelect = ({
  isAuctionClosed,
  onChange,
}: {
  isAuctionClosed: boolean;
  onChange: (isAuctionClosed: boolean) => void;
}) => {
  return (
    <div className="flex items-center gap-x-4">
      <button
        onClick={() => onChange(false)}
        className={classNames(
          'px-2 md:px-6 py-2 rounded-full border border-white font-semibold text-sm md:text-lg',
          !isAuctionClosed ? 'text-secondary bg-white' : 'text-white',
        )}
      >
        Happening Now
      </button>
      <button
        onClick={() => onChange(true)}
        className={classNames(
          'px-2 md:px-6 py-2 rounded-full border border-white font-semibold text-white text-sm md:text-lg',
          isAuctionClosed ? 'text-secondary bg-white' : 'text-white',
        )}
      >
        Closed
      </button>
    </div>
  );
};

const TypeSelect = ({
  value,
  onChange,
}: {
  value: AuctionTypeFilter;
  onChange: (value: AuctionTypeFilter) => void;
}) => {
  const options: { label: string; value: AuctionTypeFilter }[] = [
    {
      label: 'All Auctions',
      value: undefined,
    },
    {
      label: 'Regular',
      value: 'regular',
    },
    {
      label: 'Fantasy',
      value: 'fantasy',
    },
    {
      label: 'Charity',
      value: 'charity',
    },
  ];

  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const changeTypeHandler = (value: AuctionTypeFilter) => {
    onChange(value);
    setIsOpen(false);
  };

  useClickAway(wrapperRef, () => setIsOpen(false));

  return (
    <div ref={wrapperRef} className="relative">
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className="text-white font-semibold text-lg md:text-2xl flex justify-between items-center gap-x-1 w-32 md:w-40"
      >
        <span>{options.find((opt) => opt.value === value)?.label}</span>

        <HiChevronDown />
      </button>

      {isOpen && (
        <div className="w-32 md:w-40 absolute top-full flex flex-col -left-2 p-2 mt-1 bg-white/30 backdrop-blur-sm rounded-lg shadow-lg text-white font-semibold text-lg md:text-2xl">
          {options.map((opt) => (
            <button
              className={opt.value === value ? 'bg-gray-50 text-gray-700' : ''}
              onClick={() => changeTypeHandler(opt.value)}
              key={opt.value ?? opt.label}
            >
              {opt.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
