import { useLiveStreamListQuery } from '@/features/liveStream/liveStream.api';
import { LiveStreamCardContainer } from '@/features/liveStream/pages/liveStreamHistoryPage/LiveStreamCardContainer';
import NewLiveStreamButton from './Components/NewLiveStreamButton';

const LiveNow = () => {
  const { data: liveStreams } = useLiveStreamListQuery({});
  return (
    <div className="live-auctions-bg-gradient py-20">
      <h2 className="text-center text-white text-xl md:text-2xl font-bold mb-14">
        Live and Upcoming Streams
      </h2>
      {liveStreams && liveStreams.length > 0 && (
        <LiveStreamCardContainer liveStreams={liveStreams} />
      )}
      {liveStreams?.length === 0 ? (
        <div className="flex flex-col gap-y-3 items-center">
          <div className="section-title">No live streams yet.</div>
          <span className="text-white text-lg">check back soon!</span>
          <span className="text-white text-lg">Or</span>
          <NewLiveStreamButton />
        </div>
      ) : (
        <div className="flex flex-col items-center mt-5">
          <NewLiveStreamButton />
        </div>
      )}
    </div>
  );
};

export default LiveNow;
