import { Popover } from 'antd';
import cn from 'classnames';
import { formatCents } from '../../../utils/formatCents';

export function ShowcaseItemPriceView({
  price,
  priceShipping,
  priceRetail,
  isOnHoldByPendingDeal = false,
  quantity,
  showPriceLabel = true,
  forActionCard,
  isGridStyle = true,
}: {
  price: number | null;
  priceShipping: number | null;
  priceRetail: number | null;
  isOnHoldByPendingDeal?: boolean;
  quantity: number;
  showPriceLabel?: boolean;
  forActionCard?: boolean;
  isGridStyle?: boolean;
}) {
  const discount =
    price && priceRetail
      ? parseInt((((priceRetail - price) / priceRetail) * 100).toString(), 0)
      : 0;
  return (
    <div
      className={cn(
        'mt-2 text-left',
        isGridStyle
          ? ''
          : 'col-span-6 sm:col-span-4 flex flex-col sm:flex-row sm:items-center gap-1',
      )}
    >
      <div className="flex items-center">
        {showPriceLabel && <div className="text-gray-79 text-sm font-semibold mr-1">Price:</div>}
        <div className={`text-gray-01 ${forActionCard ? 'text-4xl' : 'text-lg font-bold'}`}>
          {!quantity ? (
            'Sold Out'
          ) : isOnHoldByPendingDeal ? (
            <Popover
              content={
                <div>
                  <p>The item is currently on hold by pending deal</p>
                </div>
              }
            >
              Pending Deal
            </Popover>
          ) : (
            <div className="flex flex-row items-center gap-x-2">
              <span>{price != null && formatCents(price)}</span>
              {discount > 0 && (
                <span className="text-xs text-secondary-light font-normal">
                  ({discount}% wholesaler discount)
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      {!!quantity && !isOnHoldByPendingDeal && priceShipping != null && priceShipping > 0 && (
        <div className="text-xs text-gray-01">
          + <b>{formatCents(priceShipping)}</b> shipping
        </div>
      )}
    </div>
  );
}
