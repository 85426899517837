import Head from 'next/head';

type SEOHeadProps = {
  title: string;
  description?: string;
  image?: string | null;
};
export const SEOHead = ({ title, description, image }: SEOHeadProps) => {
  return (
    <Head>
      <title>Secure Trade Club {title.length ? `- ${title}` : ''}</title>
      <meta property="og:title" content={title} />
      {description && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
        </>
      )}
      {image && <meta property="og:image" content={image} />}
    </Head>
  );
};
