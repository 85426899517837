import NewLiveStreamModal from '@/components/NewLiveStreamModal';
import { useCurrentUserId } from '@/features/user/currentUser.service';
import { useModalState } from '@/hooks/useModalState';
import routes from '@/routes/routes';
import Link from 'next/link';

const NewLiveStreamButton = () => {
  const liveStreamTypeModal = useModalState();
  const userId = useCurrentUserId();
  return (
    <>
      {userId ? (
        <>
          <button className="btn-new btn-primary" onClick={liveStreamTypeModal.open}>
            Start your own Live Stream
          </button>
          <NewLiveStreamModal
            showModal={liveStreamTypeModal.showModal}
            currentUserId={userId}
            onClose={liveStreamTypeModal.close}
          />
        </>
      ) : (
        <Link href={routes.signUp({ isBuyer: 'false' })}>
          <a>
            <button className="btn-new btn-primary">Sign-in to start streaming</button>
          </a>
        </Link>
      )}
    </>
  );
};

export default NewLiveStreamButton;
