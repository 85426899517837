import { formatDateTime } from '@/utils/datetime';
import { useState } from 'react';
import Countdown from 'react-countdown';
import { LiveStreamDto } from '../../liveStream.dto';

const ScheduleCountDown = ({ date }: { date: Date }) => {
  const startDate = date.getTime();
  const [isWaitingForStart, setisWaitingForStart] = useState(Date.now() < startDate);

  const countDownClass = 'text-primary text-lg font-bold';
  const titleClass = 'text-xs font-bold text-gray-8a';
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
  }) => {
    if (completed) return null;
    if (days > 0) {
      return (
        <span className={countDownClass}>
          {days}d {hours}h
        </span>
      );
    }
    if (hours > 0) {
      return (
        <span className={countDownClass}>
          {hours}h {minutes.toString().padStart(2, '0')}m
        </span>
      );
    }
    if (minutes > 0) {
      return (
        <span className={countDownClass}>
          {minutes.toString().padStart(2, '0')}m {seconds.toString().padStart(2, '0')}s
        </span>
      );
    }

    return <span className={countDownClass}>{seconds}s</span>;
  };

  return (
    <div className="flex flex-col justify-start items-start">
      <span className={titleClass}>Time to start</span>

      {isWaitingForStart ? (
        <Countdown
          date={startDate}
          renderer={renderer}
          onComplete={() => setisWaitingForStart(false)}
        />
      ) : (
        <span className={countDownClass}>Now (Waiting for Host)</span>
      )}
    </div>
  );
};
export const LiveStreamTime = ({
  date,
  status,
}: {
  date: string;
  status: LiveStreamDto['status'];
}) => {
  const liveStreamDate = new Date(date);
  if (status !== 'scheduled')
    return (
      <div className="flex flex-col justify-start items-start">
        <span className="text-xs font-bold text-gray-8a">
          {status === 'ended' ? 'Ended at' : 'Started at'}
        </span>
        <span className="text-primary text-lg font-bold">{date && formatDateTime(date)}</span>
      </div>
    );
  else return <ScheduleCountDown date={liveStreamDate} />;
};
