import cn from 'classnames';
import Link from 'next/link';
import { ShowcaseItemSearchDto } from '../../../api/showcaseItems/showcaseItems.dto';
import AuctionPrice from '../../../features/auctions/components/AuctionPrice';
import routes from '../../../routes/routes';
import { ShowcaseItemNumInDealsBadge } from './ShowcaseItemNumInDealsBadge';
import { ShowcaseItemPrice } from './ShowcaseItemPrice';
import ShowcaseItemTypes from './ShowcaseItemTypes';
export default function ShowcaseItemCard({
  item,
  className,
  isGridStyle = true,
}: {
  item: ShowcaseItemSearchDto;
  className?: string;
  isGridStyle?: boolean;
}) {
  const image = item.itemImages[0] || '/images/no_image.jpeg';

  return (
    <div
      className={cn(
        isGridStyle
          ? 'showcase-item-card'
          : 'flex items-center rounded-md shadow border border-gray-d9/30 p-2 sm:p-0',
        className,
      )}
    >
      <div className="relative shrink-0">
        <ShowcaseItemNumInDealsBadge item={item} className="top-0 right-0" />
        <Link href={routes.showcaseItem({ id: item.id, userId: item.userId as number })}>
          {image && (
            <img
              className={cn(
                'cursor-pointer object-cover',
                isGridStyle ? 'image rounded-t-lg' : 'h-14 w-14 rounded-md',
              )}
              src={image}
              alt={`${item.name} poster`}
            />
          )}
        </Link>
      </div>
      <div
        className={cn(
          'px-3',
          isGridStyle
            ? 'flex flex-col info py-4'
            : 'grid grid-cols-12 gap-1 sm:gap-4 items-center flex-1',
        )}
      >
        <Link href={routes.showcaseItem({ id: item.id, userId: item.userId as number })}>
          <a
            className={cn(
              'name truncate',
              isGridStyle ? '' : 'col-span-full sm:col-span-4 text-start',
            )}
          >
            <span className="font-bold text-base text-gray-79">{item.name}</span>
          </a>
        </Link>

        {item.forAuction && item.auctionInfo ? (
          <AuctionPrice isGridStyle={isGridStyle} auctionInfo={item.auctionInfo} />
        ) : (
          <ShowcaseItemPrice isGridStyle={isGridStyle} item={item} />
        )}
        <ShowcaseItemTypes item={item} isGridStyle={isGridStyle} />
      </div>

      <Link href={routes.showcaseItem({ id: item.id, userId: item.userId as number })}>
        <a
          className={cn(
            'btn-primary hover:text-gray-01',
            isGridStyle ? 'view-link rounded-b-lg' : 'm-2 p-2 rounded-lg text-sm hidden md:block',
          )}
        >
          View Item
        </a>
      </Link>
    </div>
  );
}
