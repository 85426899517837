import cn from 'classnames';
import { pluralize } from '../../../utils/pluralize';

export const ShowcaseItemNumInDealsBadge = ({
  item,
  className,
}: {
  item: { quantityInTrade: number };
  className?: string;
}) => {
  if (item.quantityInTrade <= 0) return null;

  return (
    <div
      className={cn(
        'absolute bg-blue-41 text-white text-xs py-0.5 px-2 rounded-full z-10',
        className,
      )}
    >
      {pluralize('Current Deal', item.quantityInTrade, true)}
    </div>
  );
};
