import { ShowcaseItemDto } from '../../../api/showcaseItems/showcaseItems.dto';
import { calcPrice } from '../../../utils/calcPrice';
import { ShowcaseItemPriceView } from './ShowcaseItemPriceView';

export function ShowcaseItemPrice({
  item,
  forActionCard,
  isGridStyle = true,
}: {
  item: ShowcaseItemDto;
  forActionCard?: boolean;
  isGridStyle?: boolean;
}) {
  return (
    <ShowcaseItemPriceView
      isOnHoldByPendingDeal={!item.quantityAvailable}
      quantity={item.quantity}
      price={item.isWantedItem ? null : calcPrice(item)}
      priceShipping={item.isWantedItem ? null : item.priceShipping}
      priceRetail={item.priceRetail}
      showPriceLabel={false}
      forActionCard={forActionCard}
      isGridStyle={isGridStyle}
    />
  );
}
