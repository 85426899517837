import CustomNextLink from '@/components/CustomNextLink';
import cn from 'classnames';
import Link from 'next/link';
import React from 'react';
import { ShowcaseItemSearchDto } from '../../../api/showcaseItems/showcaseItems.dto';
import { useOptionalCurrentUserDataQuery } from '../../../api/user/user.api';
import { useStartOffer } from '../../../features/trade/hooks/useStartOffer';
import {
  getIsAuctionableItem,
  getIsBuyableItem,
  getIsCashOfferableItem,
  getIsCharitableItem,
  getIsOnlyShowcaseItem,
  getIsTradeableItem,
} from '../../../features/trade/tradeOffer.utils';
import routes from '../../../routes/routes';
import { TradeOfferType } from '../../../types';
export default function ShowcaseItemTypes({
  item,
  isGridStyle = true,
}: {
  item: ShowcaseItemSearchDto;
  isGridStyle?: boolean;
}) {
  const currentUserQuery = useOptionalCurrentUserDataQuery();
  const isOwnItem = currentUserQuery.data?.id === item.userId;

  const startTrade = useStartOffer();

  const buttons = [
    getIsBuyableItem(item, item.user, currentUserQuery.data) && (
      <button
        type="button"
        disabled={isOwnItem}
        className="button"
        onClick={() => startTrade({ item, type: TradeOfferType.BUY })}
      >
        Buy
      </button>
    ),
    getIsCashOfferableItem(item, currentUserQuery.data) && (
      <button
        type="button"
        disabled={isOwnItem}
        className="button"
        onClick={() => startTrade({ item, type: TradeOfferType.CASH_OFFER })}
      >
        Cash Offer
      </button>
    ),
    getIsTradeableItem(item, currentUserQuery.data) && (
      <button
        type="button"
        className="button"
        disabled={isOwnItem}
        onClick={() => startTrade({ item, type: TradeOfferType.TRADE })}
      >
        Trade
      </button>
    ),
    getIsAuctionableItem(item, currentUserQuery.data) && (
      <CustomNextLink
        to={routes.showcaseItem({ id: item.id, userId: item.userId })}
        className="button"
      >
        Auction
      </CustomNextLink>
    ),
    getIsCharitableItem(item, item.user, currentUserQuery.data) && (
      <Link href={routes.auctions.charity.schedule}>
        <a className="button">Charity</a>
      </Link>
    ),
    getIsOnlyShowcaseItem(item, item.user, currentUserQuery.data) && (
      <CustomNextLink
        to={routes.showcaseItem({ id: item.id, userId: item.userId })}
        className="button"
      >
        Showcase {item.isWantedItem && <span> ( Wanted Item )</span>}
      </CustomNextLink>
    ),
  ].filter((button) => button);

  return (
    <div
      className={cn(
        'types flex flex-grow items-end',
        isGridStyle ? '' : 'col-span-6 sm:col-span-4 text-gray-8a text-sm',
      )}
    >
      <div className="buttons">
        {buttons.map((button, i) => (
          <React.Fragment key={i}>
            {i !== 0 && <span className="separator">/</span>}
            {button}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
