import {
  ShowcaseItemsOrderByEnum,
  useShowcaseItemsHotQuery,
  useShowcaseItemsQuery,
} from '@/api/showcaseItems/showcaseItems.api';
import ShowcaseItemCard from '@/components/@next/showcaseItem/ShowcaseItemCard';
import StandaloneLoader from '@/components/@next/StandaloneLoader/StandaloneLoader';
import * as Select from '@radix-ui/react-select';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';

type ShowcaseOrderByValue = 'hot' | 'newest' | 'recentlySold' | 'recentlyTraded';
type ShowcaseOrderByOption = { label: string; value: ShowcaseOrderByValue };

const options: ShowcaseOrderByOption[] = [
  { label: 'Hot', value: 'hot' },
  { label: 'Newest', value: 'newest' },
  { label: 'Recently Sold', value: 'recentlySold' },
  { label: 'Recently Traded', value: 'recentlyTraded' },
];

export default function ShowcaseItems() {
  const [orderBy, setOrderBy] = useState<ShowcaseOrderByOption>(options[0]);

  return (
    <div className="showcase-items-section just-listed-section">
      <h2 className="text-center text-secondary text-xl md:text-2xl font-bold mb-14">
        Check Out <CustomSelect orderBy={orderBy} setOrderBy={setOrderBy} /> Items
      </h2>

      {orderBy.value === 'hot' ? <HotItems /> : <OtherItems orderBy={orderBy.value} />}
    </div>
  );
}

const HotItems = () => {
  const { data: hotItems, isLoading: isLoadingHot } = useShowcaseItemsHotQuery();

  return (
    <>
      {isLoadingHot && (
        <div className="text-center">
          <StandaloneLoader />
        </div>
      )}

      {hotItems && hotItems.length > 0 && (
        <div className="overflow-x-auto">
          <div className="flex w-fit gap-x-8 py-5 ml-0 lg:ml-32">
            {hotItems.map((item) => (
              <ShowcaseItemCard key={item.id} item={item} />
            ))}
          </div>
        </div>
      )}

      {hotItems?.length === 0 && <div className="section-title">No items currently available</div>}
    </>
  );
};

const OtherItems = ({ orderBy }: { orderBy: 'newest' | 'recentlySold' | 'recentlyTraded' }) => {
  const filters: { orderBy: ShowcaseItemsOrderByEnum; toSell?: boolean } = useMemo(() => {
    switch (orderBy) {
      case 'newest':
        return {
          orderBy: 'newest',
        };
      case 'recentlySold':
        return {
          orderBy: 'tradedAt',
          toSell: true,
        };
      case 'recentlyTraded':
        return {
          orderBy: 'tradedAt',
        };
    }
  }, [orderBy]);

  const { data: items, isLoading } = useShowcaseItemsQuery({
    ...filters,
  });

  return (
    <>
      {isLoading && (
        <div className="text-center">
          <StandaloneLoader />
        </div>
      )}

      {items && items.length > 0 && (
        <div className="overflow-x-auto">
          <div className="flex w-fit gap-x-8 py-5 ml-0 lg:ml-32">
            {items.map((item) => (
              <ShowcaseItemCard key={item.id} item={item} />
            ))}
          </div>
        </div>
      )}
      {items?.length === 0 && <div className="section-title">No items currently available</div>}
    </>
  );
};

const CustomSelect = ({
  orderBy,
  setOrderBy,
}: {
  orderBy: ShowcaseOrderByOption;
  setOrderBy: Dispatch<SetStateAction<ShowcaseOrderByOption>>;
}) => {
  return (
    <Select.Root
      onValueChange={(value) =>
        setOrderBy(() => {
          return options.find((opt) => opt.value === value) ?? options[0];
        })
      }
      defaultValue={'hot'}
      value={orderBy.value}
    >
      <Select.Trigger className="text-blue-21 outline-0 mx-2 data-[state=open]:text-transparent">
        <Select.Value />
        <Select.Icon>
          <FaChevronDown size={16} className="inline ml-1" />
        </Select.Icon>
      </Select.Trigger>

      <Select.Portal>
        <Select.Content>
          <Select.Viewport className="bg-white/60 backdrop-blur-lg p-2 rounded-lg shadow-lg">
            {options.map((option) => (
              <Select.Item
                className="text-xl md:text-2xl cursor-pointer text-blue-21 font-bold"
                key={option.value}
                value={option.value}
              >
                <Select.ItemText>{option.label}</Select.ItemText>
              </Select.Item>
            ))}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};
