import { ReactNode } from 'react';

export type LiveStreamCardContainerViewProps = {
  children?: ReactNode;
};

export function LiveStreamCardContainerView({ children }: LiveStreamCardContainerViewProps) {
  return (
    <div className="overflow-x-auto">
      <div className="flex w-fit gap-x-8 py-5 mx-10 lg:mx-32">{children}</div>
    </div>
  );
}
