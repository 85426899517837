import { useCurrentUserId } from '@/features/user/currentUser.service';
import { useModalState } from '@/hooks/useModalState';
import routes from '@/routes/routes';
import Link from 'next/link';

const NewAuctionButton = () => {
  const liveStreamTypeModal = useModalState();
  const userId = useCurrentUserId();
  return (
    <>
      {userId ? (
        <Link href={routes.addItem}>
          <a>
            <button className="btn-new btn-primary-outline" onClick={liveStreamTypeModal.open}>
              Sell your items in Auctions
            </button>
          </a>
        </Link>
      ) : (
        <Link href={routes.signUp({ isBuyer: 'false' })}>
          <a>
            <button className="btn-new btn-primary-outline">
              Sign-in to start selling in auction
            </button>
          </a>
        </Link>
      )}
    </>
  );
};

export default NewAuctionButton;
