import { buildApiMutationHook, buildApiQueryHook, handleApiResult } from '../../utils/api';
import { queryClient } from '../../utils/queryClient';
import axiosI from '../base.api';

export type PropertyType = 'select' | 'select-multiple' | 'option';

export type PropertyDto = {
  id: number;
  parentId: number | null;
  name: string;
  fullName: string;
  slug: string;
  isSelectable: boolean;
  type: PropertyType;
  canBePopulatedByUser: boolean;
  children?: PropertyDto[];
  image: string | null;
};

export type PropertiesApiParams = {
  parentId: number | undefined;
  level: number;
};

export const useProperties = buildApiQueryHook<PropertiesApiParams, PropertyDto[]>(
  'properties',
  (params) => axiosI.get('/properties', { params }),
);

export const selectSportsFromProperties = (properties: PropertyDto[]) => {
  const sports = properties.filter((prop) => prop.isSelectable && prop.parentId === 1);
  return sports;
};

export type Links = Array<{ subTitle: PropertyDto; links: Array<PropertyDto> }>;
export type LinkType = { parent: PropertyDto; item: PropertyDto };

export const categoryToLinks = (links: Links) => {
  const result = [];
  for (const link of links) {
    for (const _link of link.links) {
      result.push({ parent: link.subTitle, item: _link });
    }
  }

  return result;
};

export const useCategoryLinks = () => {
  return buildApiQueryHook<{ parentId: number | undefined }, PropertyDto[]>('properties', (param) =>
    axiosI.get('/properties', { params: { parentId: param.parentId, level: 3 } }),
  );
};

export const getSinglePropertyChildren = async (parentId: number) => {
  const children = await handleApiResult<PropertyDto[]>(
    axiosI.get('/properties', { params: { parentId, level: 1 } }),
  );
  return children;
};

export const getSingleProperty = async (propertyId: number) => {
  const property = await handleApiResult<PropertyDto>(axiosI.get(`/properties/${propertyId}`));
  return property;
};

export const getProperties = async (parentId: number, level: number) => {
  const property = await handleApiResult<PropertyDto[]>(
    axiosI.get('/properties', {
      params: {
        parentId,
        level,
      },
    }),
  );
  return property;
};

export const getItemTypeId = async (parentId: number) => {
  if (!parentId) return;
  const children = await getSinglePropertyChildren(parentId);
  return children.find((info) => info.name === 'Item Type')?.id;
};
export type CreatePropertyDto = {
  parentId: number;
  name: string;
};

export const invalidatePropertiesField = () => {
  queryClient.invalidateQueries('properties');
};

export const useCreateCustomPropertyMutation = buildApiMutationHook<CreatePropertyDto, PropertyDto>(
  (data) => axiosI.post('/api/properties', data),
  (options) => ({
    ...options,
    onSuccess(...args) {
      invalidatePropertiesField();
      options?.onSuccess?.(...args);
    },
  }),
);
