import { ShowcaseItemDto } from '@/api/showcaseItems/showcaseItems.dto';
import routes from '@/routes/routes';
import { TradeOfferType } from '@/types';
import { serializeBoolean } from '@/utils/serializeBoolean';
import { useRouter as useNextRouter, useRouter } from 'next/router';
import { useCurrentUserId } from '../../user/currentUser.service';
import { saveNewOfferDraft } from '../offerDrafts.service';
import { getStartTradeRouteForTradeType } from '../tradeOffer.utils';

export const useStartOffer = () => {
  const currentUserId = useCurrentUserId();
  const nextRouter = useNextRouter();
  const router = useRouter();

  return ({ type, item }: { type: TradeOfferType; item: ShowcaseItemDto }) => {
    if (!currentUserId) {
      return nextRouter.push(
        routes.signUp({
          returnUrl: window.location.pathname,
          isBuyer: serializeBoolean(
            type === TradeOfferType.BUY || type === TradeOfferType.CASH_OFFER,
          ),
        }),
      );
    }

    saveNewOfferDraft({
      item,
      type,
      currentUserId,
    });

    router.push(getStartTradeRouteForTradeType(type));
  };
};
