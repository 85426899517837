import { SEOHead } from '@/components/@next/SEOHead';
import Home from '../containers/home/HomePage';
import NextApp from '../NextApp';

export default function Index() {
  return (
    <NextApp>
      <SEOHead
        title=""
        description="Buy, sell & trade all collectibles. The industry’s first true platform designed
        by Collectors for Collectors"
      ></SEOHead>
      <Home />
    </NextApp>
  );
}
