import UsernameLink from '@/features/user/components/UsernameLink';
import { UserLinkDto } from '@/types';
import Link from 'next/link';
import { BsFillEyeFill } from 'react-icons/bs';
import { LiveStreamDto } from '../../liveStream.dto';
import { LiveStreamTime } from './LiveStreamTime';

export type LiveStreamCardViewProps = {
  user: UserLinkDto;
  name: string;
  pageUrl: string;
  thumbnailUrl: string | null;
  viewerCount: number;
  date: string;
  status: LiveStreamDto['status'];
};

export function LiveStreamCardView({
  user,
  name,
  pageUrl,
  thumbnailUrl,
  viewerCount,
  date,
  status,
}: LiveStreamCardViewProps) {
  return (
    <Link href={pageUrl}>
      <a className="w-full min-w-[240px] md:w-[320px] flex flex-col shadow-lg bg-secondary-darker rounded-lg overflow-hidden h-full cursor-pointer">
        {thumbnailUrl ? (
          <img
            className="h-[320px] object-cover bg-gray-66"
            src={thumbnailUrl}
            alt="video thumbnail"
          />
        ) : (
          <div className="h-[320px] flex items-center bg-gray-66 text-white text-center justify-center">
            Preview not available
          </div>
        )}
        <div className="p-2 md:p-5 flex flex-col info flex-grow items-start">
          <div className="font-bold text-sm text-gray-8a">
            {status === 'started'
              ? 'Happening now'
              : status === 'scheduled'
              ? 'Waiting'
              : status === 'ended'
              ? 'Finished'
              : ''}
          </div>

          <UsernameLink user={user} textWhite />

          <div className="font-extrabold text-white text-lg text-start truncate mt-2">{name}</div>

          <div className="flex w-full justify-between items-end mt-2">
            <LiveStreamTime date={date} status={status} />
            {viewerCount > 0 && (
              <div className="flex items-center text-white whitespace-nowrap text-lg leading-4">
                <BsFillEyeFill className="mr-3 text-xl" />
                {viewerCount}
              </div>
            )}
          </div>
        </div>
      </a>
    </Link>
  );
}
