import routes from '../../../../routes/routes';
import { LiveStreamDto } from '../../liveStream.dto';
import { getLiveStreamThumbnailUrl } from '../../liveStream.service';
import { LiveStreamCardView } from './LiveStreamCardView';

export type LiveStreamCardProps = {
  liveStream: LiveStreamDto;
};

export function LiveStreamCard({ liveStream }: LiveStreamCardProps) {
  return (
    <LiveStreamCardView
      pageUrl={
        'forLiveAuction' in liveStream && liveStream.forLiveAuction
          ? routes.liveAuction({ userId: liveStream.user.id, liveStreamId: liveStream.id })
          : routes.liveStream({ userId: liveStream.user.id, liveStreamId: liveStream.id })
      }
      name={liveStream.name}
      viewerCount={liveStream.viewerCount}
      thumbnailUrl={getLiveStreamThumbnailUrl({ liveStream })}
      date={
        liveStream.status === 'ended'
          ? liveStream.finishedAt
          : liveStream.status === 'started'
          ? liveStream.startedAt
          : liveStream.scheduledAt
      }
      user={liveStream.user}
      status={liveStream.status}
    />
  );
}
