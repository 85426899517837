import routes from '@/routes/routes';
import Link from 'next/link';
import { FaDonate } from 'react-icons/fa';

export const Charities = () => {
  return (
    <div className="bg-green-500 text-secondary-light flex flex-col py-20 px-2 gap-y-6 items-center justify-center">
      <h2 className="text-center text-xl md:text-2xl font-bold mb-4 text-secondary-light">
        Charity Auctions
      </h2>
      <p className="text-lg">
        Introducing our new feature - Auctions for Charities! Now you can bid on unique collectibles
        while supporting a good cause!
      </p>

      <Link href={routes.auctions.charity.list}>
        <a>
          <button className="btn-new btn-secondary-outline">
            View Charity Auctions
            <FaDonate size={18} className="ml-2" />
          </button>
        </a>
      </Link>
    </div>
  );
};
