import { LiveStreamDto } from '../../liveStream.dto';
import { LiveStreamCard } from './LiveStreamCard';
import { LiveStreamCardContainerView } from './LiveStreamCardContainerView';

export type LiveStreamCardContainerProps = {
  liveStreams: LiveStreamDto[];
};

export function LiveStreamCardContainer({ liveStreams }: LiveStreamCardContainerProps) {
  return (
    <LiveStreamCardContainerView>
      {liveStreams.map((liveStream) => (
        <LiveStreamCard key={liveStream.id} liveStream={liveStream} />
      ))}
    </LiveStreamCardContainerView>
  );
}
