import { ShowcaseItemSearchDto } from '@/api/showcaseItems/showcaseItems.dto';
import { AuctionBidDto } from '@/features/auction/auctionBid.dto';
import UsernameLink from '@/features/user/components/UsernameLink';
import routes from '@/routes/routes';
import { formatCents } from '@/utils/formatCents';
import Link from 'next/link';
import AuctionCountDown from './AuctionCountdown';
import AuctionPrice from './AuctionPrice';
import { AuctionState } from './AuctionState';

export default function AuctionCardHorizontal({
  item,
  currentUserBid,
  wrapperClass,
}: {
  item: ShowcaseItemSearchDto;
  currentUserBid?: AuctionBidDto;
  wrapperClass?: string;
}) {
  const image = item.itemImages[0] || '/images/no_image.jpeg';
  if (!item.auctionInfo) return null;
  return (
    <div className={wrapperClass ?? 'w-full max-w-[320px] md:max-w-none md:w-[calc(50%-20px)]'}>
      <div className="flex flex-col md:flex-row shadow-lg bg-secondary-darker rounded-lg overflow-hidden w-full h-full">
        <Link href={routes.showcaseItem({ id: item.id, userId: item.userId as number })}>
          {image && (
            <img
              className="image md:h-48 w-full md:w-40 min-w-[160px] object-cover cursor-pointer"
              src={image}
              alt={`${item.name} for auction`}
            />
          )}
        </Link>
        <div className="p-2 md:py-4 md:pl-5 md:pr-10 flex flex-col info flex-grow">
          <AuctionState auctionInfo={item.auctionInfo} />

          <UsernameLink user={item.user} textWhite />

          <Link href={routes.showcaseItem({ id: item.id, userId: item.userId as number })}>
            <a>
              <span className="font-extrabold text-white text-lg">{item.name}</span>
            </a>
          </Link>
          <div className="flex flex-row justify-between flex-grow items-end">
            <AuctionCountDown auctionInfo={item.auctionInfo} />
            <AuctionPrice auctionInfo={item.auctionInfo} />

            {currentUserBid && (
              <div className="flex flex-col justify-start items-start">
                <span className="text-sm font-bold text-gray-8a">Your Bid</span>
                <span className="text-primary text-lg font-bold">
                  {formatCents(currentUserBid.bidAmount)}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
