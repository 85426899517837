import * as Tabs from '@radix-ui/react-tabs';
import LiveNow from './LiveNow';
import PastLiveStreams from './PastLiveStreams';
const triggerClassName =
  'pt-4 pb-2 px-6 border-b-4 border-b-transparent data-[state=active]:border-b-secondary data-[state=active]:font-bold';
export function LiveStreams() {
  return (
    <Tabs.Root className="flex flex-col" defaultValue="1">
      <Tabs.List className="flex gap-x-2 justify-center bg-primary text-secondary-light">
        <Tabs.Trigger className={triggerClassName} value="1">
          Live Streams
        </Tabs.Trigger>
        <Tabs.Trigger className={triggerClassName} value="2">
          Past Live Streams
        </Tabs.Trigger>
      </Tabs.List>

      <Tabs.Content value="1">
        <LiveNow />
      </Tabs.Content>

      <Tabs.Content value="2">
        <PastLiveStreams />
      </Tabs.Content>
    </Tabs.Root>
  );
}
